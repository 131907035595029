.Wrapper {
  width: 100%;
}

.ArticleInner {
  padding-bottom: 30px;
}

.BodyWrapper {
  position: relative;
}

$paywallHeightXs: 1338px;
$paywallHeightSm: 714px;

.Paywall {
  background-color: $white;
  bottom: 0;
  opacity: 1;
  position: relative;
  transition: opacity 0.3s;
  width: 100%;

  &::before {
    background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0.02%, $white 100%);
    content: '';
    display: inline-block;
    height: 200px;
    left: 0;
    position: absolute;
    right: 0;
    top: -200px;

    @media print {
      display: none;
    }
  }

  :global(#piano-inlined) {
    @mixin sectionDefaultMargin;
    position: relative;
    z-index: 1;
  }
}

:global(.paywall-wrapper-with-print-info) {
  @media print {
    > div {
      display: none;
    }
  }

  &::after {
    color: $blackB;
    content: 'Dies ist der Auszug eines Artikels aus unserem kostenpflichtigen Angebot. Um den vollständigen Artikel zu lesen, finden Sie unsere Angebote hier: https://www.handelszeitung.ch/service/digital-abos';
    display: none;
    font-size: 1.9rem;

    @media print {
      display: block;
    }
  }
}

.HiddenPaywall {
  display: none;
  opacity: 0;
  padding-bottom: 0;
}

/* show hint that the image might not have been downloaded */
:global(.article-detail .image-paragraph .print-info) {
  @media print {
    display: flex;
    width: 25%;
  }
}

@keyframes pulsing {
  50% {
    opacity: 0.5;
  }
}

:global(.PaywallSkeleton) {
  animation: pulsing 1.5s infinite;
  background-color: $greyE;
  border-top: 1px solid $greyB;
  min-height: $paywallHeightXs;
  position: absolute;
  top: 0;
  z-index: 0;

  @media (min-width: $mdBreakpoint) {
    min-height: $paywallHeightSm;
  }
}

.ArticleAlertsContainer {
  composes: Grid from '@grid.legacy.css'
}

.OpinionArticleHeaderBackground {
  background: $greyE;
  height: 450px;
  position: absolute;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    height: 650px;
  }
}

.ItemWrapperBorder {
  border: 1px solid $greyC;
}

.ItemWrapperPadding {
  padding: 0 16px;
}
